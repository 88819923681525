import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const options = {
  color: '#1976D2',
  loader: 'spinner',
  width: 64,
  height: 64,
  backgroundColor: '#ffffff',
  opacity: 0.5,
  zIndex: 999,
};

Vue.use(Loading, options);

// how to use:
// const loading = this.$loading.show();
// loading.hide()
