export const roles = {

  DC_ADMIN: 'DC_ADMIN',
  DC_ADMIN_PERM_LEVEL: 4,

  DC_BASIC: 'DC_BASIC',
  DC_BASIC_PERM_LEVEL: 3,

  OUTER_CLIENT_ADMIN: 'TENANT_ADMIN',
  OUTER_CLIENT_ADMIN_PERM_LEVEL: 2,

  OUTER_CLIENT_BASIC: 'TENANT_BASIC',
  OUTER_CLIENT_BASIC_PERM_LEVEL: 1,

  ALL: ['DC_ADMIN', 'DC_BASIC', 'TENANT_ADMIN', 'TENANT_BASIC'],
};
