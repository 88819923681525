export default [
  {
    id: 0,
    value: true,
    code: 'Włączona',
    label: 'Włączona podczas tworzenia dokumentów i listów przew.',
    description: [
      'Wartość edytowalna podczas tworzenia dokumentu wydania.',
      'Wymaga wprowadzenia wartości dodatkowej na dokumencie.',
    ],
    conditionalDescription: [
      'Niedostępna w terminalu.',
    ],
  },
  {
    id: 2,
    value: false,
    code: 'Włączona i ukryta',
    label: 'Włączona i ukryta podczas tworzenia dok. i listów przew.',
    description: [
      `Opcja wyklucza możliwość edycji tej usługi dodatkowej w trakcie tworzenia 
       dokumentu wydania oraz podczas tworzenia listu przewozowego w terminalu, ponieważ będzie ukryta.`,
    ],
    conditionalDescription: [],
  },
  {
    id: 1,
    value: false,
    code: 'Wyłączona',
    label: 'Wyłączona podczas tworzenia dokumentów.',
    description: [
      'Wartość edytowalna w trakcie tworzenia dokumentu wydania.',
      'Wymaga wprowadzenia wartości dodatkowej na dokumencie.',
    ],
    conditionalDescription: [
      'Niedostępna w terminalu.',
    ],
  },
];
