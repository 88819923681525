import Vue from 'vue';
import VueRouter from 'vue-router';
// import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import store from '@/store';
import routes from '@/router/routes';
import { roles } from '@/constants/Roles';
import OidcAccessDenied from '@/views/Oidc/OidcAccessDenied.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/accessDenied',
      name: 'AccessDenined',
      component: OidcAccessDenied,
      meta: {
        isPublic: true,
      },
    },
    ...routes,
  ],
});

// const oidcMiddleware = vuexOidcCreateRouterMiddleware(store, 'oidcStore');
router.beforeEach((to, from, next) => {
  const user = store.getters['oidcStore/oidcUser'];
  if (user) {
    user.role = user.roles?.find((x) => roles.ALL.some((y) => y === x));
  }

  if (user
    && user.role !== roles.DC_ADMIN
    && user.role !== roles.DC_BASIC
    && user.role !== roles.OUTER_CLIENT_ADMIN) {
    if (to.name !== 'AccessDenined' && to.name !== 'logout') {
      next({ name: 'AccessDenined', path: '/accessDenied' });
    } else {
      next();
    }
  } else {
    // console.log('in oidc', from, to)
    // oidcMiddleware(to, from, next);
    next();
  }
});
export default router;
