import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: {
          base: '#004BA0',
          lighten1: '#1976D2',
          lighten2: '#63A4FF',
          lighten3: '#BBDEFB',
          lighten4: '#EDF4FB',
        },
        nav: {
          base: '#001837',
          lighten1: '#455A64',
          lighten2: '#093768',
          lighten3: '#B0BEC5',
          lighten4: '#CFD8DC',
          lighten5: '#ECEFF1',
        },
        danger: {
          base: '#FF3D00',
          lighten1: '#FF9E80',
          lighten2: '#FFE0D9',
          lighten3: '#FFF7F5',
        },
        success: {
          base: '#4CAF50',
          lighten1: '#B9F6CA',
          lighten2: '#E8F5E9',
        },
        warning: {
          base: '#FFEB3B',
          lighten1: '#FFF9C4',
          lighten2: '#FFFDE7',
        },
        info: {
          base: '#00E5FF',
          lighten1: '#BAFFFF',
          lighten2: '#E0F7FA',
        },
        chart_teal: {
          base: '#00BEC6',
          lighten1: '#ABECE9',
        },
        chart_purple: {
          base: '#7519D2',
          lighten1: '#D7C1F1',
        },
        chart_red: {
          base: '#FC1B80',
          lighten1: '#FABFDB',
        },
        priority_red: {
          base: '#FF7043',
          lighten1: '#FFCCBC',
        },
        priority_yellow: {
          base: '#FFCA28',
          lighten1: '#FFECB3',
        },
        priority_gray: {
          base: '#BDBDBD',
          lighten1: '#F5F5F5',
        },
        priority_green: {
          base: '#26A69A',
          lighten1: '#B2DFDB',
        },
        priority_blue: {
          base: '#29B6F6',
          lighten1: '#B3E5FC',
        },
        background: {
          base: '#F2F7FB',
          lighten1: '#FFFFFF',
        },
        text: {
          base: '#333333',
          lighten1: '#7E7E7E',
          lighten2: '#D6D6D6',
        },
        navigation_group: {
          base: '#02274E',
        },
        black: '#333333',
        white: '#FFFFFF',
      },
    },
  },
});
