import {
  SET_KEYCLOAK_CLIENT,
  SET_ACCESS_TOKEN,
  SET_OIDC_IS_AUTHENTICATED,
  SET_OIDC_USER,
} from '@/store/mutation-types';

import {
  UPDATE_OIDC_INFO,
} from '@/store/action-types';

export default {
  namespaced: true,
  state: {
    access_token: '',
    isAuthenticated: false,
    oidcUser: {},
    keycloakClient: {},
  },
  mutations: {
    [SET_ACCESS_TOKEN](state, payload_access_token) {
      state.access_token = payload_access_token;
    },
    [SET_OIDC_IS_AUTHENTICATED](state, payloadIsAuthenticated) {
      state.isAuthenticated = payloadIsAuthenticated;
    },
    [SET_OIDC_USER](state, payloadOidcUser) {
      state.oidcUser = payloadOidcUser;
    },
    [SET_KEYCLOAK_CLIENT](state, keycloakClient) {
      state.keycloakClient = keycloakClient;
    },
  },
  actions: {
    [UPDATE_OIDC_INFO]({ state, commit }) {
      commit(SET_ACCESS_TOKEN, state.keycloakClient.token);
      commit(SET_OIDC_IS_AUTHENTICATED, state.keycloakClient.authenticated);
      commit(SET_OIDC_USER, state.keycloakClient.tokenParsed);
    },
  },
  getters: {
    oidcUser: (state) => state.oidcUser,
    oidcIsAuthenticated: (state) => state.isAuthenticated,
    oidcAccessToken: (state) => state.access_token,
  },
};
