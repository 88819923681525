<template>
  <v-card
    elevation="0"
    :height="height ? height : '48px'"
    :class="model ? 'check mb-5' : ' mb-5'"
  >
    <v-card-actions>
      <v-checkbox
        v-model="model"
        :true-value="true"
        :false-value="false"
        class="mt-0"
        color="primary lighten-1"
        :messages="messages"
        @click="update"
      >
        <template v-slot:label>
          <span :id="model ? 'checkboxLabel' : ''">{{ label }}</span>
        </template>
      </v-checkbox>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props:
  {
    value: {
      type: Boolean,
      default: false,
    },
    label: { type: String, default: null },
    messages: { type: String, default: null },
    height: { type: String, default: null },
  },
  data: () => ({
    model: null,
  }),
  watch: {
    value(newVal) {
      this.model = newVal;
    },
  },
  created() {
    this.model = this.value;
  },
  methods: {
    update() {
      this.$emit('input', this.model);
      this.$emit('@update');
    },
  },

};
</script>

<style scoped lang="scss">
@import "@/assets/styles/utilities/variables";
.check{
  background-color: $primary-lighten4!important;
  color: $primary-lighten1!important;
}
#checkboxLabel{
  color: $primary-lighten1!important;
}
</style>
