const ENV = process.env.VUE_APP_BUILD_TARGET_ENVIRONMENT || 'local';
const ORIGIN = `${window.location.origin}/`;

// const HOST = ENV === 'local' ? 'https://spedition-new.dataconsult.local/'
// : (ENV === 'stage' ? 'https://spedition-nubodev.dataconsult.online/' : 'https://spedition.nubowms.pl/');
const AUTH_HOST = ENV === 'local' ? 'https://auth.dataconsult.local/'
  : (ENV === 'stage' ? 'https://auth-nubodev.dataconsult.online/' : 'https://auth.nubowms.pl/');
  const CONFIG_HOST = ENV === 'local' ? 'https://spedition.dataconsult.local/configuration'
  : (ENV === 'stage' ? 'https://spedition-nubodev.dataconsult.online/configuration' : 'https://spedition.nubowms.pl/configuration');
const AUTHUI_HOST = ENV === 'local' ? 'https://auth.dataconsult.local/management/'
  : (ENV === 'stage' ? 'https://auth-nubodev.dataconsult.online/management/' : 'https://auth.nubowms.pl/management/');
const BIN_HOST = ENV === 'local' ? 'https://localhost:8084/'
  : (ENV === 'stage' ? 'https://apis.dataconsult.online/expertpack/app' : 'https://apis.dataconsult.online/expertpack/app');
const NUBO_HOST = ENV === 'local' ? 'https://nubo.dataconsult.local/'
  : (ENV === 'stage' ? 'https://panel-nubodev.dataconsult.online/' : 'https://panel.nubowms.pl/');
const ALLEGRO_HOST = ENV === 'local' ? 'https://allegro.pl.allegrosandbox.pl'
  : (ENV === 'stage' ? 'https://allegro.pl' : 'https://allegro.pl');

export const oidcSettings = {
  // authority: `${AUTH_HOST}auth/realms/nubo`,
  // clientId: 'nubo-ui',
  // client_id: 'nubo-ui',
  // redirectUri: `${ORIGIN}oidc-callback`,
  // redirect_uri: `${ORIGIN}oidc-callback-error`,
  // responseType: 'code',
  // scope: 'openid roles profile email',
  // silentRedirectUri: `${ORIGIN}silent-renew-oidc.html`,
  // silent_redirect_uri: `${ORIGIN}silent-renew-oidc.html`,
  post_logout_redirect_uri: `${ORIGIN}`,
  // If true oidc-client will try to renew your token when it is about to expire
  // automaticSilentRenew: true,
  // If true vuex-oidc will try to silently signin unauthenticated users on public routes.
  // Defaults to true
  // automaticSilentSignin: true,
  policyServerApiBaseURL: `${AUTH_HOST}policyserver-service/api/v1/`,
  spedtionConfigurationURL: `${CONFIG_HOST}`,
  authorizationUi: `${AUTHUI_HOST}`,
  binpackingURL: BIN_HOST,
  nubo: NUBO_HOST,
  speditionURL: ORIGIN,
  allegroURL: ALLEGRO_HOST,
};
