export default {
  path: '/',
  component: () => import('@/templates/Home.vue'),
  children: [

    {
      path: '/configurationSets',
      alias: '/',
      name: 'configurationSets',
      meta: {
        title: 'Konfiguracja wysyłki',
        name: 'Kurierzy i sposoby wysyłki',
        submenu: true,
      },
      component: () => import('@/views/ConfigurationSets.vue'),
    },
    {
      path: '/organizationConfigurationSets',
      alias: '/',
      name: 'organizationConfigurationSets',
      meta: {
        title: 'Konfiguracja wysyłki',
        name: 'Kurierzy i sposoby wysyłki',
        submenu: true,
      },
      component: () => import('@/views/OrganizationConfigurationSets.vue'),
    },
  ],
};
