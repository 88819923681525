// OIDC
import OidcAccessDenied from '@/views/Oidc/OidcAccessDenied.vue';
import OidcLogout from '@/views/Oidc/OidcLogout.vue';

export default [
  {
    path: '/accessDenied', // Needs to match redirect_uri in you oidcSettings
    name: 'oidcAccessDenied',
    component: OidcAccessDenied,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/logout', // Needs to match redirect_uri in you oidcSettings
    name: 'oidcLogout',
    component: OidcLogout,
    meta: {
      isPublic: true,
    },
  },
];
