<template>
  <div>
    logging out
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { oidcSettings } from '@/oidc';

export default {
  name: 'Logout',
  data: () => ({}),
  async beforeMount() {
    try {
      await this.keycloakClient.logout({
        redirectUri: oidcSettings.post_logout_redirect_uri,
      });
    } catch (err) {
      console.log('logout failed', err);
      this.$router.push('/');
    }
  },
  computed: {
    ...mapState('oidcStore', ['keycloakClient']),
  },
};
</script>

<style scoped lang="scss">

</style>
