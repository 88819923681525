import Keycloak from 'keycloak-js';
import store from '@/store';
import { SET_KEYCLOAK_CLIENT } from '@/store/mutation-types';
import { UPDATE_OIDC_INFO } from '@/store/action-types';
import router from '@/router';

const ENV = process.env.VUE_APP_BUILD_TARGET_ENVIRONMENT || 'local';

const TOKEN_MIN_VALIDITY_SECONDS = 15;

function updateOidcStore() {
  store.dispatch(`oidcStore/${UPDATE_OIDC_INFO}`);
}

function initKeycloak() {
  const keycloakClient = Keycloak(`/keycloak-config/keycloak_${ENV}.json`);

  keycloakClient.onAuthSuccess = () => {
    updateOidcStore();
  };

  keycloakClient.onReady = () => {
    store.commit(`oidcStore/${SET_KEYCLOAK_CLIENT}`, keycloakClient);
    updateOidcStore();
  };

  keycloakClient.onAuthError = () => {
    updateOidcStore();
  };

  keycloakClient.onAuthRefreshSuccess = () => {
    updateOidcStore();
  };

  keycloakClient.onAuthRefreshError = () => {
    updateOidcStore();
  };

  keycloakClient.onAuthLogout = () => {
    updateOidcStore();
  };

  keycloakClient.onTokenExpired = () => {
    updateOidcStore();
  };

  setInterval(async () => {
    try {
      const refreshed = await keycloakClient.updateToken(
        TOKEN_MIN_VALIDITY_SECONDS,
      );
      if (refreshed) {
        // await store.dispatch(`signalR/${NOTIFICATION_STOP}`);
        // store.dispatch(`signalR/${NOTIFICATION_CONNECT}`);
      }
    } catch {
      router.push({ name: 'oidcLogout' });
    }
  }, 5 * 1000);

  return keycloakClient.init({
    onLoad: 'login-required',
  });
}

export { initKeycloak };
