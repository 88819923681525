import navigation from '@/router/routes/navigation';
import simple from '@/router/routes/simple';
import oidc from '@/router/routes/oidc';

const routes = [
  ...oidc,
  ...simple,
  navigation,
];

export default routes;
