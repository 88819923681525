<template>
  <v-card
    elevation="0"
  >
    <v-card-title class="pb-0">
      <v-tooltip
        v-if="!!tooltip.icon "
        bottom
        color="black"
        :max-width="maxWidth"
        class="tooltip"
      >
        <template v-slot:activator="{on, attrs}">
          <v-icon
            v-bind="attrs"
            class="mr-3 pb-1"
            v-on="on"
          >
            {{ tooltip.icon }}
          </v-icon>
        </template>
        <slot name="message">
          <span>
            {{ tooltip.message }}.
          </span>
        </slot>
      </v-tooltip>
      {{ title.toUpperCase() }}
    </v-card-title>
    <v-divider
      v-if="!!title"
      class="mt-1 mb-3"
    />
    <v-container>
      <slot />
    </v-container>
  </v-card>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: { type: String, default: '' },
    tooltip: {
      type: Object,
      default: () => ({
        icon: '',
        message: '',
      }),
    },
    maxWidth: { type: Number, default: 400 },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/utilities/variables";

.v-card:not(:first-child) {
  margin-top: 15px;
}

.v-tooltip__content {
  background-color: $black;
  opacity: 1 !important;
}
</style>
