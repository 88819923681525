import Vue from 'vue';
import Vuex from 'vuex';
import simpleTop from '@/store/modules/simpleTop';
import oidcStore from '@/store/modules/oidc';
import createPersistedState from 'vuex-persistedstate';
import spedition from './modules/spedition';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    simpleTop,
    oidcStore,
    spedition,
  },
  plugins: [createPersistedState()],
});

export default store;
